export default [
    {
        name: "project",
        required: true,
        label: "Gender",
        align: "left",
        sortable: true,
        field: (i: any) => i.value
    },
    {
        name: "type",
        required: true,
        label: "Total",
        align: "left",
        sortable: true,
        field: (i: any) => i.total
     },
    // {
    //     name: "number",
    //     required: true,
    //     label: "Number",
    //     align: "left",
    //     sortable: true,
    //     field: (i: any) => i.number
    // }
];