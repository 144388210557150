
















































import { Component, Prop, Vue } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker.vue";
import reportColumns from "@/data/columns/ReportColumns";
import { saveAs } from "file-saver";

@Component({
  components: {
    DatePicker,
  },
})
export default class ReportsSelect extends Vue {
  @Prop()
  public reportType!: string;

  private from = " ";
  private to = " ";
  private columns!: any;
  private reportItems: any = [];
  private items = [];
  private options = [
    { label: "of ongoing case", value: "OngoingCase" },
    { label: "of litigation case", value: "LitigationCase" },
    { label: "unique beneficiaries", value: "Beneficiaries" },
    { label: "of legal counselling sessions", value: "CounsellingSessions" },
    { label: "of legal preparations", value: "LegalPreparations" },
    { label: "of legal representations", value: "LegalRepresentations" },
    { label: "Representation in th Asylum Service", value: "AsylumRepresentation" },
    { label: "Other type of representation", value: "OtherRepresentation" },
    { label: "of psychosocial sessions", value: "PsychosocialSessions" },
    { label: "of family reunification cases (Dublin)", value: "ReunificationCase" },
    { label: "Appeals to authorities", value: "AuthorityAppeal" },
    { label: "Meeting & Communication with public actors for a specific case", value: "PublicActors"},
    { label: "of Escorts", value: "Escorts" },
    { label: "referals", value: "Referals" },
  ];

  created() {
    this.columns = reportColumns;
    const currentDate = new Date();
    this.to = currentDate.toISOString().split("T")[0];
    currentDate.setMonth(currentDate.getMonth() - 1);
    this.from = currentDate.toISOString().split("T")[0];

    if(!this.reportType){
      this.$router.push({name: 'Reports'})
    }

  }

  async addReportItem() {
    this.reportItems = [];
    this.items.forEach(async (i: any) => {
      const reportFilters = { ReportName: i.value, ReportType: this.reportType, StartDate: this.from, EndDate: this.to }
      const data = await this.$service.getReport("Reports", reportFilters);
      this.reportItems.push({ name: i.label, data });
    });
  }

  async download() {
    const reportFilters = { ReportName: "", ReportType: this.reportType, StartDate: this.from, EndDate: this.to };
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(await this.$service.downloadReports(reportFilters),
    `${this.reportType}_Report_${utc}.xlsx`
    );
  }
}
